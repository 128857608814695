html {
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ol-mouse-position {
  color: white;
  font-weight: bold;
  right: 70px;
}

.ol-zoom {
  top: initial;
  left: initial;
  right: .5em;
  bottom: .5em;
}

.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
}
.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-control.ol-layerswitcher {
  top: 10px;
}

.ol-control.ol-layerswitcher-popup {
  bottom: initial;
  left: initial;
  right: .5em;
  top: 8px;
}

.draw-polygon {
  right: .5em;
  bottom: 80px;
}

.ol-touch .draw-polygon {
  bottom: 80px;

}.layers {
   right: .5em;
   top: 30px;
 }

.ol-touch .layers {
  bottom: 30px;
}

.ol-zoom-in:focus {

}

.ol-button i
{	color: inherit;
}
.ol-button.ol-active button
{	background: rgba(60, 136, 0, 0.7)

}
.select
{	top:0.5em;
  right:50%;
}
.edit
{	top:0.5em;
  left:50%;
}

.wgs84-mouse-position {
  color: white;
  font-weight: bold;
  right: 70px;
  top: 28px;
  position: absolute;
}